/* eslint-disable max-len */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
import React, {
  useState, useEffect, useCallback
} from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { Fade } from 'react-awesome-reveal';
import * as Yup from 'yup';
import Confetti from 'react-confetti';
import { FiUser } from 'react-icons/fi';
import {
  Container, Row, Col
} from 'react-bootstrap';
import Banner from '../../../../components/Banner';
import Button from '../../../../components/Button';
import Section from '../../../../components/Section';
import ContentSection from '../../../../components/ContentSection';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import InputBlock from '../../../../components/InputBlock';
import Overlay from '../../../../components/Overlay';
import MembersLoginModal from '../../../../components/MembersLoginModal';
import TextBlock from '../../../../components/TextBlock';
import ModalBlock from '../../../../components/ModalBlock';
import Logo from '../../../../assets/img/logo_gradient.svg';
import BannerImage from '../../../../assets/img/banner-image_default.png';
import * as helper from '../../../../helpers/helper';
import * as membershipService from '../../../../services/management/membershipService';
import * as userService from '../../../../services/management/userService';

import * as authRedux from '../../../../redux/authRedux';
import * as alertToast from '../../../../redux/alertToastRedux';

const initialModel = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  companyName: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  password: '',
  confirmPassword: '',
};

const RegistrationPage = (props) => {
  const {
    auth, showAlert, history, login, iconSize
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [model] = useState(initialModel);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [userList, setUserList] = useState([]);
  // SUCCESSFUL MEMBERSHIP CREATION
  const [isThankYouModalOpen, setIsThankYouModalOpen] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiDimensions, setConfettiDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    if (auth && auth.authToken) {
      history.push('/geaux-engage-member-profile');
    }
  }, [auth, history]);

  useEffect(() => {
    const handleResize = () => {
      setConfettiDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const formik = useFormik({
    initialValues: initialModel,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required('Please enter your first name')
        .max(100, 'First name cannot exceed 100 characters'),
      lastName: Yup.string()
        .required('Please enter your last name')
        .max(100, 'Last name cannot exceed 100 characters'),
      emailAddress: Yup.string()
        .required('Please enter your email address')
        .max(100, 'Email address cannot exceed 100 characters')
        .email('Please enter a valid email address')
        .test('checkEmailExists', 'Email already exists', async (value) => {
          if (!value) return true;
          const emailExists = await doesEmailExist(value);
          return !emailExists;
        }),
      phoneNumber: Yup.string().nullable(),
      businessName: Yup.string()
        .max(255, 'Business name cannot exceed 255 characters'),
      addressLine1: Yup.string()
        .required('Please enter your street address')
        .max(255, 'Street address cannot exceed 255 characters'),
      addressLine2: Yup.string()
        .nullable()
        .max(255, 'Address line 2 cannot exceed 255 characters'),
      city: Yup.string()
        .required('Please enter your city')
        .max(100, 'City name cannot exceed 100 characters'),
      state: Yup.string()
        .required('Please enter your state')
        .max(100, 'State name cannot exceed 100 characters'),
      zipCode: Yup.string()
        .required('Please enter your ZIP/postal code')
        .max(20, 'ZIP/postal code cannot exceed 20 characters'),
      password: Yup.string()
        .required('Password is required')
        .min(8, 'Minimum password length must be at least 8 characters')
        .max(100),
      confirmPassword: Yup.string()
        .required('Please confirm your password')
        .oneOf([Yup.ref('password')], 'Passwords do not match'),
    }),
    onSubmit: (values, { setSubmitting }) => {
      submitForm(values, setSubmitting);
    },
  });

  const submitForm = async (values, setSubmitting) => {
    setSubmitting(true);
    try {
      const res = await membershipService.submitMembership(values);
      showAlert({ text: res.message, state: 'success' });

      formik.resetForm();
      setShowConfetti(true);
      setIsThankYouModalOpen(true);
    } catch (error) {
      showAlert({ text: error.message, state: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  const doesEmailExist = async (emailAddress) => {
    try {
      const res = await membershipService.emailExists(emailAddress);
      return res;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const navigateToPage = useCallback((page) => {
    history.push(page);
  }, [history]);

  const handleLoginModalOpen = () => {
    setIsLoginModalOpen(true);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Registration"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="main-content">
        {/* MEMBERSHIP INTRO */}
        <ContentSection
          isTextOnly
          hasRoundCorners
          title="Join Our Community"
          description="Register as a member to gain access to exclusive content, networking opportunities, and more."
          descriptionColor="dark-clr"
          // primaryActionText="Preview Our Demo"
          // primaryActionTo="/geaux-engage-member-profile-demo"
          secondaryActionText="Already a Member? Sign In"
          // secondaryActionOnClick={handleLoginModalOpen}
          secondaryActionOnClick={() => {
            const currentAccessLevel = auth.accessLevel;
            if (currentAccessLevel >= 40) {
              navigateToPage('/admin/management/members');
            }
            if (currentAccessLevel <= 30 && currentAccessLevel > 0) {
              navigateToPage('/geaux-engage-member-profile');
            } else {
              handleLoginModalOpen();
            }
          }}
        />

        <ContentBlock className="content-section pb-100 m-0 p-0">
          {/* MEMBERSHIP SIGNUP FORM */}
          <FormBlock className="content-container--card-style--with-shadow shadow--1 mx-auto" style={{ width: '100%', maxWidth: '1000px' }} onSubmit={formik.handleSubmit}>
            <CustomBlock className="">
              <Fade className="p-0">
                <h2 className="size-xl fw-700 secondary-animated-gradient--clr mb-60 text-center font--main fw-800">
                  Membership Signup
                </h2>
              </Fade>

              <Container fluid className="p-0">
                {/* CONTACT DETAILS */}
                <>
                  <Fade direction="left" className="pl-0 fit-content">
                    <h3 className="size-mdlg fw-700 primary-lighter--clr mb-20 p-0 subheader">Contact Details</h3>
                  </Fade>
                  <Row>
                    <Col xs={12} sm={6} md={6} xl={6}>
                      <InputBlock
                        isRequired
                        label="First Name"
                        placeholder="John"
                        errorMessage={formik.touched.firstName && formik.errors.firstName}
                        inputState={`${helper.getInputClasses(formik, 'firstName')}`}
                        {...formik.getFieldProps('firstName')}
                      />
                    </Col>

                    <Col xs={12} sm={6} md={6} xl={6}>
                      <InputBlock
                        isRequired
                        label="Last Name"
                        placeholder="Smith"
                        errorMessage={formik.touched.lastName && formik.errors.lastName}
                        inputState={`${helper.getInputClasses(formik, 'lastName')}`}
                        {...formik.getFieldProps('lastName')}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-40">
                    <Col xs={12} sm={6} md={6} xl={6}>
                      <InputBlock
                        label="Phone Number"
                        placeholder="(555) 555-5555"
                        mask="(999) 999-9999"
                        errorMessage={formik.touched.phoneNumber && formik.errors.phoneNumber}
                        inputState={`${helper.getInputClasses(formik, 'phoneNumber')}`}
                        {...formik.getFieldProps('phoneNumber')}
                      />
                    </Col>

                    <Col xs={12} sm={6} md={6} xl={6}>
                      <InputBlock
                        isRequired
                        label="Email Address"
                        placeholder="address@email.com"
                        errorMessage={formik.touched.emailAddress && formik.errors.emailAddress}
                        inputState={`${helper.getInputClasses(formik, 'emailAddress')}`}
                        {...formik.getFieldProps('emailAddress')}
                      />
                    </Col>
                  </Row>
                </>

                {/* ACCOUNT DETAILS */}
                <>
                  <Fade direction="left" className="pl-0 fit-content">
                    <h3 className="size-mdlg fw-700 primary-lighter--clr mb-20 p-0 subheader">Account Details</h3>
                  </Fade>
                  <Row className="mb-40">
                    <Col xs={12} sm={6} md={6} xl={6}>
                      <InputBlock
                        isRequired
                        label="Password"
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder="Enter password"
                        inputRightButtonText={passwordVisible ? 'Hide' : 'Show'}
                        inputRightButtonOnClick={() => setPasswordVisible(!passwordVisible)}
                        errorMessage={formik.touched.password && formik.errors.password}
                        inputState={`${helper.getInputClasses(formik, 'password')}`}
                        {...formik.getFieldProps('password')}
                      />
                    </Col>

                    <Col xs={12} sm={6} md={6} xl={6}>
                      <InputBlock
                        isRequired
                        label="Confirm Password"
                        type={passwordConfirmVisible ? 'text' : 'password'}
                        placeholder="Confirm password"
                        inputRightButtonText={passwordConfirmVisible ? 'Hide' : 'Show'}
                        inputRightButtonOnClick={() => setPasswordConfirmVisible(!passwordConfirmVisible)}
                        errorMessage={formik.touched.confirmPassword && formik.errors.confirmPassword}
                        inputState={`${helper.getInputClasses(formik, 'confirmPassword')}`}
                        {...formik.getFieldProps('confirmPassword')}
                      />
                    </Col>
                  </Row>
                </>

                {/* ADDRESS INFORMATION */}
                <>
                  <Fade direction="left" className="pl-0 fit-content">
                    <h3 className="size-mdlg fw-700 primary-lighter--clr mb-20 p-0 subheader">Address Information</h3>
                  </Fade>
                  <Row>
                    <Col xs={12} sm={12} md={6} xl={6}>
                      <InputBlock
                        label="Company Name"
                        placeholder="Smith & Co."
                        errorMessage={formik.touched.businessName && formik.errors.businessName}
                        inputState={`${helper.getInputClasses(formik, 'businessName')}`}
                        {...formik.getFieldProps('businessName')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6} xl={6}>
                      <InputBlock
                        isRequired
                        label="Street Address"
                        placeholder="123 Main St"
                        errorMessage={formik.touched.addressLine1 && formik.errors.addressLine1}
                        inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                        {...formik.getFieldProps('addressLine1')}
                      />
                    </Col>

                    <Col xs={12} md={6} xl={6}>
                      <InputBlock
                        label="Apt/Suite/Unit"
                        placeholder="Apt 1"
                        errorMessage={formik.touched.addressLine2 && formik.errors.addressLine2}
                        inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                        {...formik.getFieldProps('addressLine2')}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-40">
                    <Col xs={12} md={6} xl={6}>
                      <InputBlock
                        isRequired
                        label="City"
                        placeholder="Baton Rouge"
                        errorMessage={formik.touched.city && formik.errors.city}
                        inputState={`${helper.getInputClasses(formik, 'city')}`}
                        {...formik.getFieldProps('city')}
                      />
                    </Col>

                    <Col xs={12} sm={6} md={3} xl={2}>
                      <InputBlock
                        isRequired
                        label="State"
                        placeholder="LA"
                        errorMessage={formik.touched.state && formik.errors.state}
                        inputState={`${helper.getInputClasses(formik, 'state')}`}
                        {...formik.getFieldProps('state')}
                      />
                    </Col>

                    <Col xs={12} sm={6} md={3} xl={2}>
                      <InputBlock
                        isRequired
                        label="ZIP/Postcode"
                        placeholder="12345"
                        errorMessage={formik.touched.zipCode && formik.errors.zipCode}
                        inputState={`${helper.getInputClasses(formik, 'zipCode')}`}
                        {...formik.getFieldProps('zipCode')}
                      />
                    </Col>
                  </Row>
                </>

                <CustomBlock className="content-container--actions">
                  <Button
                    text="Create Account"
                    className="btn size-xs fw-400 lh-1 success--bg"
                    size="xs"
                    onClick={formik.handleSubmit}
                    isDisabled={formik.isSubmitting || !formik.isValid}
                  />
                </CustomBlock>
              </Container>
            </CustomBlock>
          </FormBlock>
        </ContentBlock>

      </CustomBlock>

      {/* LOGIN MODAL */}
      <MembersLoginModal
        isVisible={isLoginModalOpen}
        setIsVisible={setIsLoginModalOpen}
        showAlert={showAlert}
        history={history}
        login={login}
        iconSize={iconSize}
      />

      {/* SUCCESSFUL REGISTRATION MODAL */}
      <ModalBlock
        hasCloseAction
        isCentered
        isVisible={isThankYouModalOpen}
        size="md"
        // contentHeader=""
        primaryModalActionText="Continue to login"
        primaryModalActionType=""
        primaryModalActionColor="success--bg"
        primaryModalActionOnClick={() => {
          setIsLoginModalOpen(true);
          setShowConfetti(false);
          setIsThankYouModalOpen(false);
        }}
        secondaryModalActionText="Close"
        secondaryModalActionColor="mid-grey--bg"
        secondaryModalActionOnClick={() => {
          setShowConfetti(false);
          setIsThankYouModalOpen(false);
        }}
        onHide={() => {
          setShowConfetti(false);
          setIsThankYouModalOpen(false);
        }}
        buttonSize="xs"
      >
        <CustomBlock className="d-flex flex-column align-items-center justify-content-center mb-4">
          <p className="size-mdlg fw-700 secondary-animated-gradient--clr mb-4">Thank you for joining!</p>
          <img className="my-3" src={Logo} alt="Geaux Engage Logo" style={{ maxWidth: '15rem' }} />
        </CustomBlock>
      </ModalBlock>

      {showConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          numberOfPieces={500}
          initialVelocityY={5}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 100,
          }}
          // drawShape={(ctx) => {
          //   // Draw an envelope/message icon
          //   ctx.beginPath();

          //   // Draw rectangle (body of the envelope)
          //   ctx.moveTo(0, 0);
          //   ctx.lineTo(20, 0); // Top edge
          //   ctx.lineTo(20, 10); // Right edge
          //   ctx.lineTo(0, 10); // Bottom edge
          //   ctx.closePath(); // Connects back to the start (left edge)

          //   // Draw the flap of the envelope
          //   ctx.moveTo(0, 0); // Start at top left
          //   ctx.lineTo(10, 5); // Middle point (flap)
          //   ctx.lineTo(20, 0); // Top right

          //   ctx.stroke(); // Outline the shape
          //   ctx.closePath(); // Close the current path
          // }}
        />
      )}
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  { ...authRedux.actions, ...alertToast.actions }
)(RegistrationPage);