/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable object-curly-newline */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
import { Nav, NavItem } from 'react-bootstrap';
// ICONS
import { MdOutlineEvent } from 'react-icons/md';
import { RiFileExcel2Line } from 'react-icons/ri';
import { TbCheck, TbX, TbCalendarPlus } from 'react-icons/tb';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
import ConfirmModal from '../../../../components/ConfirmModal';
// HELPERS AND SERVICES
import * as eventService from '../../../../services/management/eventService';
import * as helper from '../../../../helpers/helper';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as authRedux from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const EventListingPage = (props) => {
  const { history, showAlert, auth, showConfirmModal, hideConfirmModal } = props; // eslint-disable-line
  const [isLoading, setIsLoading] = useState(false);
  const [events, setEvents] = useState([]);
  // OTHER
  const iconSize = 22;
  const iconColor = 'white--clr';

  useEffect(() => {
    fetchEvents();
  }, [history.location.pathname]);

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const data = await eventService.getAllEvents();
      setEvents(data);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteEvent = async (event) => {
    try {
      await eventService.deleteEvent(event.id);
      fetchEvents();
      showAlert({ text: 'Event deleted successfully', state: 'success' });
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    }
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    rowsPerPage: 100,
    textLabels: { pagination: { jumpToPage: 'Page No:' } },
    onPageChange: (currentPage) => {
      console.log(currentPage);
    },
    onRowsPerPageChange: (rowsPerPage) => {
      console.log(rowsPerPage);
    },
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="info--bg ml-0"
              size="xxs"
              onClick={() => history.push(`/cms/management/event/${events[dataIndex].id}`)}
            />
            <Button
              text="Remove"
              className="danger--bg ml-1"
              size="xxs"
              onClick={() => {
                showConfirmModal({
                  title: 'Remove Event',
                  text: `Are you sure you want to remove ${events[dataIndex].title}?`,
                  rightBtnText: 'Confirm',
                  btnAction: () => handleDeleteEvent(events[dataIndex])
                });
              }}
            />
          </CustomBlock>
        ),
      },
    },
    {
      name: 'imageUrl',
      label: 'Image',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const eventImageURL = `${process.env.REACT_APP_API_URL}Attachments/${events[dataIndex].imageUrl}`;

          return (
            <div
              className=""
              style={{
                width: '150px',
                height: '75px',
                backgroundImage: `url(${eventImageURL})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '5px',
                overflow: 'hidden',
                boxShadow: 'rgba(60, 64, 67, 0.15) 0px 1px 2px 0px, rgba(60, 64, 67, 0.10) 0px 1px 3px 1px'
              }}
            />
          );
        },
      }
    },
    {
      name: 'title',
      label: 'Event',
      options: columnOptions,
    },
    {
      name: 'addressLine1',
      label: 'Address',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const {
            addressLine1, addressLine2, city, state, zipcode
          } = events[dataIndex];

          const address = [
            addressLine1 ? `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}` : '',
            city ? `${city}${state ? `, ${state}` : ''}` : state || '',
            zipcode || '',
          ].filter(Boolean).join('\n');

          return (
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {address}
            </div>
          );
        },
      },
    },
    {
      name: 'start',
      label: 'Date',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const startDate = moment(events[dataIndex].start).format('M/D/YY h:mm A');
          const endDate = moment(events[dataIndex].end).format('M/D/YY h:mm A');

          return (
            <div>
              <p><span className="size-xxxs light-grey--clr fw-400 text-uppercase mr-2">Starts</span>{startDate}</p>
              <p><span className="size-xxxs light-grey--clr fw-400 text-uppercase mr-2">Ends</span>{endDate}</p>
            </div>
          );
        },
      },
    },
    {
      name: 'isActive',
      label: 'Is Active',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = events[dataIndex];
          return (
            isActive ? <TbCheck size={26} strokeWidth={2} className="success--clr" /> : <TbX size={26} strokeWidth={2} className="danger--clr" />
          );
        }
      },
    },
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              {/* HEADER */}

              <ContentHeader
                title={(
                  <CustomBlock className="container-fluid pl-0 mb-30">
                    <CustomBlock className="mb-10" style={{ width: '200px', hieght: 'auto' }}>
                      <svg
                        width="150" viewBox="0 0 500 237" fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M397.459 125.966V111.766H362.145H361.199H346.823V177.909H361.21H362.145H397.459V163.709H362.145V152.124H383.445V137.924H362.145V125.966H397.459Z" fill="#023D5B" />
                        <path d="M339.35 111.766H288.778L288.527 112.012L274.327 125.966H295.897V181.732L311.218 196.781V125.966H324.895L339.35 111.766Z" fill="#023D5B" />
                        <path d="M251.532 111.766L233.781 144.837L216.031 111.766H200.336V177.909H216.031V141.011L225.934 159.46L233.781 174.079L241.629 159.46L251.532 141.011V177.909H267.227V111.766H251.532Z" fill="#023D5B" />
                        <path d="M412.239 146.145L420.067 138.316V111.766H404.746V177.909H420.067V153.974L412.239 146.145Z" fill="#023D5B" />
                        <path d="M153.042 177.909H170.441L154.615 148.488C158.374 145.898 161.141 142.107 162.463 137.737V137.704C163.616 133.874 163.603 129.788 162.425 125.966C162.425 125.947 162.425 125.925 162.407 125.906C161.13 121.807 158.577 118.224 155.119 115.68C151.661 113.136 147.48 111.764 143.188 111.766H111.121V177.909H126.442V151.938H139.073L153.042 177.909ZM148.184 131.803C148.184 132.578 148.031 133.345 147.735 134.061C147.438 134.777 147.004 135.427 146.456 135.975C145.908 136.523 145.257 136.958 144.541 137.254C143.825 137.551 143.058 137.704 142.283 137.704H126.45V125.966H143.124C144.528 126.168 145.811 126.87 146.739 127.942C147.667 129.014 148.177 130.385 148.176 131.803H148.184Z" fill="#023D5B" />
                        <path d="M177.541 111.766H170.661L177.541 118.634V177.909H193.236V111.766H177.541Z" fill="#023D5B" />
                        <path d="M414.608 193.625V196.782H404.709V183.703H408.229V193.625H414.608Z" fill="#023D5B" />
                        <path d="M430.975 193.625V196.782H421.058V183.703H424.578V193.625H430.975Z" fill="#023D5B" />
                        <path d="M443.457 183.516C444.557 183.511 445.643 183.769 446.623 184.269C447.603 184.769 448.45 185.496 449.092 186.389L446.043 188.101C445.75 187.699 445.364 187.373 444.919 187.15C444.473 186.928 443.981 186.816 443.483 186.823C441.581 186.823 440.239 188.355 440.239 190.246C440.239 192.137 441.581 193.665 443.483 193.665C443.981 193.673 444.474 193.56 444.92 193.338C445.366 193.116 445.753 192.79 446.047 192.387L449.092 194.102C448.449 194.994 447.602 195.72 446.622 196.218C445.642 196.717 444.557 196.974 443.457 196.969C441.673 196.969 439.962 196.26 438.701 194.998C437.439 193.737 436.73 192.026 436.73 190.242C436.73 188.458 437.439 186.747 438.701 185.486C439.962 184.224 441.673 183.516 443.457 183.516Z" fill="#023D5B" />
                        <path d="M436.585 146.168L436.551 146.138L436.701 145.992L470.927 111.766H449.257L414.881 146.145L425.714 156.979L425.718 156.975L465.651 196.781H487.317L436.585 146.168Z" fill="#7EC242" />
                        <path d="M115.034 101.003H95.9908V196.78L89.2643 203.462L85.9534 206.75L79.2269 213.436L73.5692 219.056V207.404H75.8674V205.91H73.5692V203.66H78.9242V202.165H73.5692V200.659H75.8674V199.165H73.5692V196.915H78.9242V195.42H73.5692V193.918H75.8674V192.423H73.5692V190.17H78.9242V188.675H73.5692V187.173H75.8674V185.678H73.5692V183.428H78.9242V181.934H73.5692V180.428H75.8674V178.933H73.5692V176.683H78.9242V175.188H73.5692V173.686H75.8674V172.191H73.5692V169.938H78.9242V168.443H73.5692V166.941H75.8674V165.446H73.5692V163.197H78.9242V161.702H73.5692V160.196H75.8674V158.701H73.5692V156.452H78.9242V154.957H73.5692V153.455H75.8674V151.96H73.5692V149.706H78.9242V148.212H73.5692V146.709H75.8674V145.215H73.5692V142.965H78.9242V141.47H73.5692V139.964H75.8674V138.469H73.5692V136.22H78.9242V134.725H73.5692V133.223H75.8674V131.728H73.5692V129.475H78.9242V127.98H73.5692V126.478H75.8674V124.983H73.5692V122.733H78.9242V121.239H73.5692V119.733H75.8674V118.238H73.5692V115.988H78.9242V114.493H73.5692V112.991H75.8674V111.496H73.5692V109.243H78.9242V107.748H73.5692V106.246H75.8674V104.751H73.5692V102.502H78.9242V101.007H73.5692V101.003H27.8704L12.6836 85.7901V81.9336H95.9945L96.1103 82.0494L99.354 85.2968L107.467 93.4246L115.034 101.003Z" fill="#023D5B" />
                        <path d="M115.034 28.6836L107.467 36.2621L99.354 44.3899L96.1103 47.6373L95.9908 47.7531V74.8234H73.5692V73.2277H75.8674V71.7329H73.5692V69.4833H78.9242V67.9885H73.5692V66.4825H75.8674V64.9878H73.5692V62.7382H78.9242V61.2434H73.5692V59.7411H75.8674V58.2464H73.5692V55.993H78.9242V54.4982H73.5692V52.996H75.8674V51.5012H73.5692V49.2516H78.9242V47.7568H73.5692V47.7531H12.6836V43.8966L27.8704 28.6836H115.034Z" fill="#023D5B" />
                        <path d="M149.802 53.9078L147.425 51.5274L147.317 51.4228L140.19 44.2964L139.94 44.0461L124.578 28.6836L113.905 39.36L111.095 42.1664L112.974 44.0461L113.225 44.2964L133.942 65.0139L131.581 67.3719L117.018 81.9347L113.655 85.2979L113.27 85.6828L111.278 87.6784L111.203 87.7531L113.58 90.1298L124.45 101.004L124.578 101.131L147.317 78.3921L160.803 64.9056L149.802 53.9078Z" fill="#7EC242" />
                      </svg>
                    </CustomBlock>
                    <p className="size-xs fw-500 mid-grey--clr">View Events Managed by Primtek</p>
                  </CustomBlock>
                )}
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add New Event' : ''}
                primaryButtonIconLeft={<TbCalendarPlus size={20} />}
                primaryButtonBackgroundColor="success--bg"
                primaryButtonOnClick={() => history.push('/cms/management/create-event')}
              />

              {/* DATA TABLE */}
              <CustomBlock>
                <MUIDataTable
                  data={events}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...authRedux.actions,
  ...alert.actions,
  ...confirmModal.actions,
})(EventListingPage);