/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
// COMPONENTS
import MUIDataTable from 'mui-datatables';
// ICONS
import { BiSolidUserPlus } from 'react-icons/bi';
import { TbCheck, TbX } from 'react-icons/tb';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import ContentHeader from '../../../../components/ContentHeader';
import Button from '../../../../components/Button';
import OverLay from '../../../../components/Overlay';
// HELPERS AND SERVICES
import * as memberService from '../../../../services/management/memberService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as authRedux from '../../../../redux/authRedux';
import * as confirmModal from '../../../../redux/confirmModalRedux';

const NetworkListingPage = (props) => {
  const {
    history,
    showAlert,
    auth,
    showConfirmModal,
    hideConfirmModal
  } = props;
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const iconSize = 22;
  const iconColor = 'white--clr';

  const fetchMembers = () => {
    setIsLoading(true);
    memberService.getAllMembers().then((res) => {
      setMembers(res);
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchMembers();
  }, []);

  const deleteMember = (memberId) => {
    setIsLoading(true);

    memberService.deleteMember(memberId).then((res) => {
      showAlert({ text: res.message, state: 'success' });
      fetchMembers();
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
      hideConfirmModal();
    });
  };

  const columnOptions = {
    filter: true,
    sort: true,
    print: false,
    download: true,
  };

  const options = {
    selectableRows: 'none',
    download: true,
    print: false,
    jumpToPage: true,
    rowsPerPage: 100,
    sortOrder: {
      name: 'name',
      direction: 'asc'
    },
    textLabels: { pagination: { jumpToPage: 'Page No:' } }
  };

  const columns = [
    {
      name: 'id',
      label: 'Actions',
      options: {
        filter: false,
        sort: false,
        print: false,
        download: false,
        customBodyRenderLite: (dataIndex) => (
          <CustomBlock className="content-container--actions flex-start pl-0 mt-0">
            <Button
              text="Edit"
              className="primary--bg ml-0"
              size="xxs"
              onClick={() => {
                history.push(`/admin/management/network/${members[dataIndex].id}`);
              }}
            />
            {auth.isAdmin && (

              <Button
                text="Remove"
                className="danger--bg ml-1"
                size="xxs"
                onClick={() => {
                  showConfirmModal({
                    title: 'Remove Network',
                    text: `Are you sure you want to remove ${members[dataIndex].name}?`,
                    rightBtnText: 'Confirm',
                    btnAction: () => {
                      deleteMember(members[dataIndex].id);
                    }
                  });
                }}
              />
            )}
          </CustomBlock>
        )
      }
    },
    {
      name: 'logo',
      label: 'Logo',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const member = members[dataIndex];
          return (
            <img
              src={`${process.env.REACT_APP_API_URL}Attachments/${member.imageFilePath}`}
              alt={`${member.name} Logo`}
              title={`${member.name} Logo`}
              style={{ maxHeight: '40px' }}
            />
          );
        }
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: columnOptions,
    },
    {
      name: 'type',
      label: 'Type',
      options: columnOptions,
    },
    {
      name: 'isActive',
      label: 'Is Active',
      options: {
        ...columnOptions,
        customBodyRenderLite: (dataIndex) => {
          const { isActive } = members[dataIndex];
          return (
            isActive ? <TbCheck size={26} strokeWidth={2} className="success--clr" /> : <TbX size={26} strokeWidth={2} className="danger--clr" />
          );
        }
      },
    }
  ];

  return (
    <>
      {isLoading && <OverLay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <CustomBlock className="content-container--card-style--with-shadow">
              <ContentHeader
                title="Network Members"
                headerSize="lg"
                primaryButtonText={auth.isAdmin ? 'Add Network' : ''}
                primaryButtonIconLeft={<BiSolidUserPlus className={iconColor} size={iconSize} />}
                primaryButtonOnClick={() => {
                  history.push('/admin/management/create-network');
                }}
              />

              <CustomBlock>
                <MUIDataTable
                  data={members}
                  columns={columns}
                  options={options}
                />
              </CustomBlock>
            </CustomBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...authRedux.actions,
    ...alert.actions,
    ...confirmModal.actions
  }
)(NetworkListingPage);