/* eslint-disable react/self-closing-comp */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-undef */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Fade from 'react-awesome-reveal';
// ICONS
import { Link, useLocation } from 'react-router-dom';
import { IoFilter } from 'react-icons/io5';
import { SiEventbrite } from 'react-icons/si';
import { TbWorldWww, TbBuilding, TbSortAscendingLetters, TbSortDescendingLetters, TbShieldCheck, TbSortAscending, TbSortDescending } from 'react-icons/tb'; // eslint-disable-line
// COMPONENTS
import { Container, Row, Col, Modal, ButtonGroup, Button as BSButton, Spinner } from 'react-bootstrap'; // eslint-disable-line
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import Button from '../../../components/Button';
import Banner from '../../../components/Banner';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Overlay from '../../../components/Overlay';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
// SERVICES
import * as eventService from '../../../services/management/eventService';
import * as eventbriteService from '../../../services/management/eventbriteService';

const EventCountdown = ({ event }) => {
  const [timeSegments, setTimeSegments] = useState([]);

  useEffect(() => {
    const updateCountdown = () => {
      const now = moment();
      const eventStart = moment(event.start);
      const duration = moment.duration(eventStart.diff(now));

      const months = Math.floor(duration.asMonths());
      const weeks = Math.floor(duration.asWeeks());
      const days = Math.floor(duration.asDays()) % 7;
      const hours = duration.hours();
      const minutes = duration.minutes();

      const spans = [];

      if (months >= 2) {
        spans.push(`${months} month${months > 1 ? 's' : ''}`);
      } else if (weeks > 2) {
        spans.push(`${weeks} week${weeks > 1 ? 's' : ''}`);
        if (days > 0) {
          spans.push(`${days} day${days > 1 ? 's' : ''}`);
        }
      } else if (days > 7) {
        spans.push(`${days} day${days > 1 ? 's' : ''}`);
      } else {
        if (days > 0) {
          spans.push(`${days} day${days > 1 ? 's' : ''}`);
        }
        if (days <= 7 && hours > 0) {
          spans.push(`${hours} hour${hours > 1 ? 's' : ''}`);
        }
        if (!spans.length) {
          spans.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
        }
      }

      setTimeSegments(spans);
    };

    updateCountdown();
    const interval = setInterval(updateCountdown, 60000);

    return () => clearInterval(interval);
  }, [event.start]);

  return (
    <>
      {timeSegments.map((segment) => (
        <span key={segment} className="size-xs primary-lighter--clr fw-500 lh-normal">{segment}</span>
      ))}
    </>
  );
};

const EventsPage = (props) => {
  const { history } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [EventDetailsModal, setEventDetailsModal] = useState(false);
  // EVENTS
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [eventFilterType, setEventFilterType] = useState('all');
  const [filteredEvents, setFilteredEvents] = useState([]);
  // EVENT FILTERS
  const [filter, setFilter] = useState('all');
  const [sortOrder, setSortOrder] = useState('asc');

  // useEffect(() => {
  //   if (filteredEvents.length > 0) {
  //     console.log('FILTERED EVENTS \n', filteredEvents);
  //   }
  // }, [filteredEvents]);

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    applyFiltersAndSort();
  }, [events, filter, sortOrder]);

  const getEvents = async () => {
    setIsLoading(true);
    try {
      const combinedEvents = await fetchEventData();
      setEvents(combinedEvents);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEventData = async () => {
    try {
      const [primtekDataRes, eventbriteDataRes] = await Promise.all([
        eventService.getAllActiveEvents(),
        eventbriteService.getEventsByStatus('live'),
      ]);
      return [
        ...mapToEventResource(primtekDataRes, 'primtek'),
        ...mapToEventResource(eventbriteDataRes, 'eventbrite'),
      ];
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const applyFiltersAndSort = () => {
    let updatedEvents = [...events];

    if (filter === 'upcoming') {
      const now = new Date();
      const oneWeekFromNow = new Date();
      oneWeekFromNow.setDate(now.getDate() + 7);
      updatedEvents = updatedEvents.filter(
        (event) => new Date(event.start) >= now && new Date(event.start) <= oneWeekFromNow
      );
    }

    updatedEvents.sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setFilteredEvents(updatedEvents);
  };

  const mapToEventResource = (data, source) => {
    const mappers = {
      primtek: (event) => ({
        id: event.id,
        source: 'primtek',
        title: event.title,
        description: event.description,
        address: {
          addressLine1: event.addressLine1,
          addressLine2: event.addressLine2,
          city: event.city,
          state: event.state,
          zipCode: event.zipcode,
        },
        start: event.start,
        end: event.end,
        imageUrl: `${process.env.REACT_APP_API_URL}Attachments/${event.imageUrl}`,
        link: event.primaryButtonLink,
        status: event.isActive ? 'live' : 'inactive',
        ticketsSold: null,
        ticketsTotal: null,
        primaryAction: {
          type: event.primaryButtonActionType,
          text: event.primaryButtonText,
          link: event.primaryButtonLink,
        },
        secondaryAction: {
          type: event.secondaryButtonActionType,
          text: event.secondaryButtonText,
          link: event.secondaryButtonLink,
        },
      }),
      eventbrite: (event) => ({
        id: event.id,
        source: 'eventbrite',
        title: event.title,
        description: event.description,
        address: {
          addressLine1: event.address.addressLine1,
          addressLine2: event.address.addressLine2,
          city: event.address.city,
          state: event.address.state,
          zipCode: event.address.zipCode,
        },
        start: event.start,
        end: event.end,
        imageUrl: event.imageUrl,
        link: event.link,
        status: event.status,
        ticketsSold: event.ticketsSold,
        ticketsTotal: event.ticketsTotal,
      }),
    };

    return data.map(mappers[source]);
  };

  const handleSelectedEvent = (selectedOption) => {
    setSelectedEvent(selectedOption);
  };

  const handleFilterChange = (filterOption) => {
    setFilter(filterOption);

    if (filterOption === 'all') {
      setFilteredEvents(events);
    } else if (filterOption === 'upcoming') {
      const now = new Date();
      const oneWeekFromNow = new Date();
      oneWeekFromNow.setDate(now.getDate() + 7);

      const upcoming = events.filter(
        (event) => new Date(event.start) >= now && new Date(event.start) <= oneWeekFromNow
      );
      setFilteredEvents(upcoming);
    }
  };

  const handleSortOrderChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    const sortedEvents = [...filteredEvents].sort((a, b) => {
      const dateA = new Date(a.start);
      const dateB = new Date(b.start);

      return newSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setFilteredEvents(sortedEvents);
  };

  useEffect(() => {
    if (selectedEvent && selectedEvent.id) {
      // console.log('Selected Event\n', selectedEvent);
      setEventDetailsModal(true);
    }
  }, [selectedEvent]);

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase title="Events"
        isDynamicBanner imageSource={BannerImage}
      />

      <CustomBlock className="main-content">
        {/* INTRO SECTION */}
        <CustomBlock className="content-section">
          <CustomBlock className="d-flex flex-column">
            <p className="size-xl fw-800 primary--clr mb-10">Opportunities to Geaux Engage</p>
            <p className="size-md mid-grey--clr">Connecting you with networking and community events to enhance referral marketing opportunities.</p>
            {/* <Fade>
              <Button
                text="Your Referral Future"
                className="size-sm secondary-gradient--bg circle"
                size="sm"
                onClick={() => history.push('/geaux-engage-registration')}
                style={{ background: 'linear-gradient(45deg, #FF3C00 40%, #FB8500 100%)' }}
              />
            </Fade> */}
          </CustomBlock>
        </CustomBlock>

        {/* EVENT FILTERS */}
        <CustomBlock className="content-section">
          <Container
            fluid
            className="filters-container p-4 rounder light--sbg--gradient"
            style={{
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
              border: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          >
            <CustomBlock className="d-flex align-items-center gap-2 mb-4 primary-lighter--clr">
              <p className="size-md fw-600">Filter and Sort Events</p>
            </CustomBlock>

            <CustomBlock className="d-flex flex-wrap gap-3 mb-20">
              <ButtonGroup aria-label="Filter Events" className="flex-wrap">
                <BSButton
                  variant="none"
                  className={`filter-btn ${filter === 'all' ? 'primary-lighter--sbg--gradient white--clr' : ''}`}
                  style={filter !== 'all' ? { border: '1px solid var(--primary-lighter-color)' } : { background: 'var(--primary-lighter-color)' }}
                  onClick={() => handleFilterChange('all')}
                >
                  All Events
                </BSButton>
                <BSButton
                  variant="none"
                  className={`filter-btn ${filter === 'upcoming' ? 'primary-lighter--sbg--gradient white--clr' : ''}`}
                  style={filter !== 'upcoming' ? { border: '1px solid var(--primary-lighter-color)' } : { background: 'var(--primary-lighter-color)' }}
                  onClick={() => handleFilterChange('upcoming')}
                >
                  Upcoming Events
                </BSButton>
              </ButtonGroup>

              <ButtonGroup>
                <BSButton
                  variant="none"
                  className={`sort-btn ${sortOrder === 'asc' ? 'primary-lighter--sbg--gradient active' : 'primary-lighter--sbg--gradient'}`}
                  onClick={handleSortOrderChange}
                >
                  {sortOrder === 'asc' ? (
                    <TbSortAscending size={24} className="white--clr" />
                  ) : (
                    <TbSortDescending size={24} className="white--clr" />
                  )}
                </BSButton>
              </ButtonGroup>
            </CustomBlock>
          </Container>
        </CustomBlock>

        {/* EVENT LISTING */}
        <Container fluid className="my-4 px-0" style={{ width: 'min(90%, 1500px)' }}>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1250: 3 }}>
            {(isLoading && !events.length > 0) ? (
              <Spinner />
            ) : (
              <Masonry gutter="20px">
                {filteredEvents.map((event) => (
                  <CustomBlock className="event-card overflow-hidden rounder white--bg" style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                    <CustomBlock
                      style={{
                        backgroundImage: `url(${event.imageUrl})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '250px',
                      }}
                    />
                    <CustomBlock className="d-flex flex-column gap-3 p-4 h-100">
                      <h5 className="size-md fw-700 primary-animated-gradient--clr lh-1">{event.title}</h5>
                      <CustomBlock className="d-flex flex-column align-items-start gap-1">
                        <p className="d-flex flex-wrap gap-2 size-xs primary-lighter--clr fw-500">
                          <span className="lh-normal">{moment(event.start).format('MMM D').toUpperCase()}</span>
                          <span className="lh-normal primary-lightest--clr">•</span>
                          <span className="event-countdown d-flex align-items-center gap-2">
                            <EventCountdown event={event} />
                          </span>
                        </p>

                        <p className="d-flex flex-wrap gap-2 size-xs primary-lighter--clr fw-500">
                          {(event.start || event.end) && (
                            <>
                              <span className="lh-normal">{moment(event.start).format('h:mmA')}</span>
                              {event.end && (
                                <>
                                  <span className="lh-normal primary-lightest--clr">-</span>
                                  <span className="lh-normal">{moment(event.end).format('h:mmA')}</span>
                                </>
                              )}
                            </>
                          )}
                          {(event.address.city || event.address.state) && (
                            <>
                              <span className="lh-normal primary-lightest--clr">•</span>
                              <span className="lh-normal">
                                {event.address?.city}
                                {event.address?.city && ', '}
                                {event.address?.state}
                              </span>
                            </>
                          )}
                        </p>
                        <p className="size-sm mid-grey--clr mt-20 fw-300 mb-20">{event.description}</p>
                      </CustomBlock>

                      {event.source === 'eventbrite' && (
                      <Button
                        text="Event Details"
                        iconLeft={event.source === 'eventbrite' ? <SiEventbrite /> : ''}
                        className="light--clr primary--bg--gradient fw-400"
                        onClick={() => window.open(event.link, '_blank')}
                      />
                      )}

                      {event.source === 'primtek' && (
                      <CustomBlock className="d-flex flex-wrap align-items-center gap-2">
                        <Button
                          text="Event Details"
                          className="primary--bg--gradient flex-grow-1 fw-400"
                          onClick={() => setSelectedEvent(event)}
                        />
                        {event.primaryAction.text && (
                        <Button
                          text={event.primaryAction.text}
                          className="primary-lighter--bg--gradient flex-grow-1 fw-400"
                          style={{ flexBasis: 'calc(50% - 0.5rem)' }}
                          onClick={() => window.open(event.primaryAction.link, '_blank')}
                        />
                        )}
                        {event.secondaryAction.text && (
                        <Button
                          text={event.secondaryAction.text}
                          className="primary-lighter--bg--gradient flex-grow-1 fw-400"
                          style={{ flexBasis: 'calc(50% - 0.5rem)' }}
                          onClick={() => window.open(event.secondaryAction.link, '_blank')}
                        />
                        )}
                      </CustomBlock>
                      )}
                    </CustomBlock>
                  </CustomBlock>
                ))}
              </Masonry>
            )}
          </ResponsiveMasonry>
        </Container>
      </CustomBlock>

      {/* EVENT DETAILS MODAL */}
      <Modal
        show={EventDetailsModal}
        onHide={() => {
          setEventDetailsModal(false);
          setSelectedEvent([]);
        }}
        centered
        size="lg"
      >
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title>
            <span className="primary-animated-gradient--clr">
              {selectedEvent?.title}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mt-20">
          {selectedEvent && (
            <div className="event-details d-flex align-items-center flex-wrap gap-4">
              <div className="event-details d-flex align-items-center justify-content-center w-100">
                <a
                  href={selectedEvent?.imageUrl || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'block',
                    // maxWidth: '250px',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    margin: '0 auto',
                  }}
                >
                  <img
                    src={selectedEvent?.imageUrl || ''}
                    alt={selectedEvent?.title || 'Event Image'}
                    style={{
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                      borderRadius: '8px',
                    }}
                  />
                </a>
              </div>

              <div className="event-info">
                <div className="d-flex flex-column flex-wrap gap-2">
                  <p className="d-flex flex-wrap gap-2 size-xs primary-lighter--clr fw-500">
                    <span className="lh-normal">{moment(selectedEvent?.start).format('MMM D').toUpperCase()}</span>
                    <span className="lh-normal primary-lightest--clr">•</span>
                    <span className="event-countdown d-flex align-items-center gap-2">
                      <EventCountdown event={selectedEvent} />
                    </span>
                  </p>

                  <p className="d-flex flex-wrap gap-2 size-xs primary-lighter--clr fw-500">
                    {(selectedEvent?.start || selectedEvent?.end) && (
                    <>
                      <span className="lh-normal">{moment(selectedEvent?.start).format('h:mmA')}</span>
                      {selectedEvent?.end && (
                      <>
                        <span className="lh-normal primary-lightest--clr">-</span>
                        <span className="lh-normal">{moment(selectedEvent?.end).format('h:mmA')}</span>
                      </>
                      )}
                    </>
                    )}
                  </p>

                  <p className="d-flex flex-wrap gap-2 size-xs primary-lighter--clr fw-500">
                    {selectedEvent?.address?.addressLine1 && (
                    <span className="lh-normal">
                      {selectedEvent?.address?.addressLine1}
                      {(selectedEvent?.address?.addressLine2 || selectedEvent?.address?.city || selectedEvent?.address?.state) && ','}
                    </span>
                    )}
                    {selectedEvent?.address?.addressLine2 && (
                    <span className="lh-normal">
                      {selectedEvent?.address?.addressLine2}
                      {(selectedEvent?.address?.city || selectedEvent?.address?.state) && ','}
                    </span>
                    )}
                    {(selectedEvent?.address?.city || selectedEvent?.address?.state) && (
                    <span className="lh-normal">
                      {selectedEvent?.address?.city}
                      {selectedEvent?.address?.city && selectedEvent?.address?.state && ', '}
                      {selectedEvent?.address?.state}
                    </span>
                    )}
                  </p>
                </div>

                <p className="size-sm mid-grey--clr mt-20 fw-300 mb-20">{selectedEvent?.description}</p>

                {selectedEvent?.primaryAction?.link && (
                <div className="actions-container mt-40">
                  {selectedEvent?.link && (
                  <>
                    {selectedEvent?.source === 'primtek' && (
                    <CustomBlock className="d-flex flex-wrap align-items-center gap-2">
                      {selectedEvent?.primaryAction?.text && (
                      <Button
                        text={selectedEvent.primaryAction.text}
                        className="primary-lighter--bg--gradient flex-grow-1 fw-400"
                        style={{ flexBasis: 'calc(50% - 0.5rem)', padding: '12px 0' }}
                        onClick={() => window.open(selectedEvent.primaryAction.link, '_blank')}
                      />
                      )}
                      {selectedEvent?.secondaryAction?.text && (
                      <Button
                        text={selectedEvent.secondaryAction.text}
                        className="primary-lighter--bg--gradient flex-grow-1 fw-400"
                        style={{ flexBasis: 'calc(50% - 0.5rem)', padding: '12px 0' }}
                        onClick={() => window.open(selectedEvent.secondaryAction.link, '_blank')}
                      />
                      )}
                    </CustomBlock>
                    )}
                  </>
                  )}
                </div>
                )}

              </div>

            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EventsPage;