/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
// DEPENDENCIES
import React, { useState } from 'react';
// ASSETS & COMPONENTS
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomBlock from './CustomBlock';

const InfiniteScroller = ({ logos, imageHeight, imageSpacing, desaturateImages, showDescriptions, className, scrollContentClassName, tooltipClassName, scrollSpeed, scrollDirection }) => {
  const [isPaused, setIsPaused] = useState(false);

  const logosForScrolling = [...logos, ...logos];
  const animationDuration = `${100 / scrollSpeed}s`;
  const animationName = scrollDirection === 'right' ? 'scroll-animation-reverse' : 'scroll-animation';

  return (
    <CustomBlock className={`infinite-scroller-container ${className}`}>
      <div
        className={`scroll-content ${scrollContentClassName || ''}`}
        style={{
          animation: `${animationName} ${animationDuration} linear infinite`,
          animationPlayState: isPaused ? 'paused' : 'running',
        }}
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        {logosForScrolling.map((logo) => (
          <OverlayTrigger
            placement="bottom"
            onEntering={(node) => {
              node.classList.remove('hide');
              node.classList.add('show');
            }}
            onExiting={(node) => {
              node.classList.remove('show');
              node.classList.add('hide');
            }}
            overlay={(
              <Tooltip id={`tooltip-${logo.id}`} className={`custom-tooltip ${tooltipClassName || ''}`}>
                {logo.title}
                <div className="hr" />
                {showDescriptions && <p className="tooltip-description">{logo.companyDescription}</p>}
              </Tooltip>
            )}
          >
            <img
              src={logo.src}
              alt={logo.alt}
              style={{
                height: `${imageHeight}px`,
                marginRight: `${imageSpacing}px`,
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
              className={`logo ${desaturateImages ? 'logo__desaturate' : ''}`}
            />
          </OverlayTrigger>
        ))}
      </div>
    </CustomBlock>
  );
};

export default InfiniteScroller;