/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
// COMPONENTS
import { TextField } from '@material-ui/core';
// ICONS
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import TextAreaBlock from '../../../../components/TextAreaBlock';
import SelectBlock from '../../../../components/SelectBlock';
import CheckboxBlock from '../../../../components/CheckboxBlock';
import Image from '../../../../components/Image';
import Overlay from '../../../../components/Overlay';
import FileUploadBlock from '../../../../components/FileUploadBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// HELPERS AND SERVICES
import constants from '../../../../constants/constants';
import * as helper from '../../../../helpers/helper';
import * as eventService from '../../../../services/management/eventService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const linkTypes = [
  { value: constants.CONTENT_SECTION.ButtonActionTypePage, label: 'Page' },
  { value: constants.CONTENT_SECTION.ButtonActionTypeLink, label: 'Link (New Tab)' },
  { value: constants.CONTENT_SECTION.ButtonActionTypePhoneNumber, label: 'Phone Number' },
  { value: constants.CONTENT_SECTION.ButtonActionTypeEmail, label: 'Email Address' },
];

const initialEventModel = {
  id: 0,
  isActive: true,
  title: '',
  description: '',
  addressTitle: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipcode: '',
  start: new Date().toISOString().slice(0, -8),
  end: new Date().toISOString().slice(0, -8),
  schedule: '',
  mapUrl: '',
  imageUrl: '',
  primaryButtonText: '',
  primaryButtonActionType: '',
  primaryButtonLink: '',
  secondaryButtonText: '',
  secondaryButtonActionType: '',
  secondaryButtonLink: '',
  sortOrder: 0,
};

const EventManagementPage = (props) => {
  const { history, showAlert } = props;
  const { eventId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState(initialEventModel);
  const [file, setFile] = useState([]);
  const [primaryActionType, setPrimaryActionType] = useState(null);
  const [secondaryActionType, setSecondaryActionType] = useState(null);

  useEffect(() => {
    if (eventId) {
      fetchEvent();
    }
  }, []);

  const fetchEvent = () => {
    setIsLoading(true);
    eventService.getEventById(eventId).then((res) => {
      setEvent(res);
      if (res.primaryButtonActionType) {
        const selectedPrimary = linkTypes.find((x) => x.value === res.primaryButtonActionType);
        if (selectedPrimary) {
          setPrimaryActionType(selectedPrimary);
        }
      }

      if (res.secondaryButtonActionType) {
        const selectedSecondary = linkTypes.find((x) => x.value === res.secondaryButtonActionType);
        if (selectedSecondary) {
          setSecondaryActionType(selectedSecondary);
        }
      }
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: event,
    validationSchema: Yup.object().shape({
      id: Yup.number(),
      isActive: Yup.bool(),
      title: Yup.string().required('Event Title is required').max(250),
      description: Yup.string().nullable().max(2000),
      addressTitle: Yup.string().nullable().max(250),
      addressLine1: Yup.string().required('Street Address is required').max(250),
      addressLine2: Yup.string().nullable().max(250),
      city: Yup.string().required('City is required').max(250),
      state: Yup.string().required('State is required').max(250),
      zipcode: Yup.string().required('Zipcode is required').max(250),
      start: Yup.string().required('Event Start Date is required'),
      end: Yup.string().nullable(),
      schedule: Yup.string().nullable().max(2000),
      mapUrl: Yup.string().nullable().max(2000),
      imageUrl: Yup.string().max(2000),
      primaryButtonText: Yup.string().nullable().max(50),
      primaryButtonActionType: Yup.string().nullable().when('primaryButtonText', {
        is: (primaryButtonText) => !primaryButtonText || primaryButtonText.length === 0,
        then: Yup.string().nullable().max(500),
        otherwise: Yup.string().nullable().max(500).required('Primary Action Type is required')
      }),
      primaryButtonLink: Yup.string().nullable().max(500).when('primaryButtonText', {
        is: (primaryButtonText) => !primaryButtonText || primaryButtonText.length === 0,
        then: Yup.string().nullable().max(500),
        otherwise: Yup.string().nullable().max(500).required('Primary Action Link is required')
      }),
      secondaryButtonText: Yup.string().nullable().max(50),
      secondaryButtonActionType: Yup.string().nullable().when('secondaryButtonText', {
        is: (secondaryButtonText) => !secondaryButtonText || secondaryButtonText.length === 0,
        then: Yup.string().nullable().max(500),
        otherwise: Yup.string().nullable().max(500).required('Secondary Action Type is required')
      }),
      secondaryButtonLink: Yup.string().nullable().max(500).when('secondaryButtonText', {
        is: (secondaryButtonText) => !secondaryButtonText || secondaryButtonText.length === 0,
        then: Yup.string().nullable().max(500),
        otherwise: Yup.string().nullable().max(500).required('Secondary Action Link is required')
      }),
    }),
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (eventId) {
        updateEvent(values);
      } else {
        createEvent(values);
      }
    },
  });

  const updateEvent = async (model) => {
    setIsLoading(true);
    try {
      if (file.length > 0) {
        model = { ...model, image: file[0].file };
      }
      const res = await eventService.updateEvent(helper.convertJsonToFormData(model));
      history.push('/cms/management/events');
      showAlert({ text: res.message, state: 'success' });
    } catch (ex) {
      showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const createEvent = async (model) => {
    if (file.length === 0) {
      showAlert({ text: 'Please upload an image for the event', state: 'warning' });
      return;
    }
    setIsLoading(true);
    try {
      const res = await eventService.createEvent(helper.convertJsonToFormData({
        ...model,
        image: file.length > 0 ? file[0].file : null,
      }));
      showAlert({ text: res.message, state: 'success' });
      history.push(`/cms/management/event/${res.id}`);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded">
        <Section isFullWidth>
          <ContentBlock>
            <FormBlock onSubmit={formik.submitForm}>

              {/* CONTENT DETAILS */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow"
              >
                <ContentBlock>
                  <ContentHeader
                    title={eventId ? 'Event Details' : 'New Event'}
                    headerSize="lg"
                  />
                </ContentBlock>

                <ContentBlock>
                  <ContentHeader
                    title="Basic Information"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock className="mb-15">
                  <CheckboxBlock
                    label="Is Event Active?"
                    id="isActive"
                    {...formik.getFieldProps('isActive')}
                    isChecked={formik.values.isActive}
                  />
                </ContentBlock>

                <ContentBlock cols={8}>
                  <InputBlock
                    isRequired
                    label="Title"
                    placeholder="e.g. Event Title"
                    errorMessage={formik.errors.title}
                    inputState={`${helper.getInputClasses(formik, 'title')}`}
                    {...formik.getFieldProps('title')}
                  />
                </ContentBlock>

                <ContentBlock cols={8}>
                  <TextAreaBlock
                    label="Description"
                    placeholder="e.g. Enter a brief description of the event here, including key details such as the event's purpose, activities, etc."
                    errorMessage={formik.errors.description}
                    inputState={`${helper.getInputClasses(formik, 'description')}`}
                    {...formik.getFieldProps('description')}
                  />
                </ContentBlock>

                {/* LOCATION */}
                <ContentBlock className="mt-30">
                  <ContentHeader
                    title="Location"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={12}>
                  <ContentBlock cols={8}>
                    <InputBlock
                      label="Title"
                      placeholder="e.g. Location Title"
                      errorMessage={formik.errors.addressTitle}
                      inputState={`${helper.getInputClasses(formik, 'addressTitle')}`}
                      {...formik.getFieldProps('addressTitle')}
                    />
                  </ContentBlock>
                </ContentBlock>

                <ContentBlock cols={8}>
                  <InputBlock
                    required
                    label="Street Address"
                    placeholder="e.g. 123 Main St"
                    errorMessage={formik.errors.addressLine1}
                    inputState={`${helper.getInputClasses(formik, 'addressLine1')}`}
                    {...formik.getFieldProps('addressLine1')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="Apt/Unit/Suit"
                    placeholder="e.g. Apt. B"
                    errorMessage={formik.errors.addressLine2}
                    inputState={`${helper.getInputClasses(formik, 'addressLine2')}`}
                    {...formik.getFieldProps('addressLine2')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="City"
                    placeholder="e.g. Baton Rouge"
                    errorMessage={formik.errors.city}
                    inputState={`${helper.getInputClasses(formik, 'city')}`}
                    {...formik.getFieldProps('city')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="State"
                    placeholder="e.g. Louisiana"
                    errorMessage={formik.errors.state}
                    inputState={`${helper.getInputClasses(formik, 'state')}`}
                    {...formik.getFieldProps('state')}
                  />
                </ContentBlock>

                <ContentBlock cols={4}>
                  <InputBlock
                    required
                    label="Zip/Post Code"
                    placeholder="e.g. 12345"
                    errorMessage={formik.errors.zipcode}
                    inputState={`${helper.getInputClasses(formik, 'zipcode')}`}
                    {...formik.getFieldProps('zipcode')}
                  />
                </ContentBlock>

                {/* SCHEDULE */}
                <ContentBlock className="mt-30">
                  <ContentHeader
                    title="Schedule"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={3} className="ml-5 mr-5">
                  <span className="static-label dark--clr fw-600 pb-15">Start Date & Time</span>
                  <TextField
                    required
                    id="datetime-local"
                    type="datetime-local"
                    defaultValue={new Date().toISOString().slice(0, -8)}
                    InputProps={{ disableUnderline: true, style: { fontWeight: 500 } }}
                    errorMessage={formik.errors.start}
                    inputState={`${helper.getInputClasses(formik, 'start')}`}
                    {...formik.getFieldProps('start')}
                  />
                </ContentBlock>

                <ContentBlock cols={3} className="ml-5">
                  <span className="static-label dark--clr fw-600 pb-15">End Date & Time</span>
                  <TextField
                    id="datetime-local"
                    type="datetime-local"
                    // defaultValue={new Date().toISOString().slice(0, -8)}
                    InputProps={{ disableUnderline: true, style: { fontWeight: 500 } }}
                    errorMessage={formik.errors.end}
                    inputState={`${helper.getInputClasses(formik, 'end')}`}
                    {...formik.getFieldProps('end')}
                  />
                </ContentBlock>

                <ContentBlock cols={8} className="mt-10">
                  <TextAreaBlock
                    label="Description"
                    placeholder="e.g. Enter a brief list of scheduled items for the event:
                    8:00 am - Registration and Networking
                    9:00 am - Welcome and Introduction
                    9:15 am - Keynote Speaker: John Smith"
                    errorMessage={formik.errors.schedule}
                    inputState={`${helper.getInputClasses(formik, 'schedule')}`}
                    {...formik.getFieldProps('schedule')}
                  />
                </ContentBlock>
              </Section>

              {/* EVENT MAP & IMAGE */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mt-30"
              >
                <ContentBlock>
                  <ContentHeader
                    title="Event Map"
                    subtitle="Upload a Google Maps emdedded iFrame"
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <ContentBlock cols={6}>
                    <TextAreaBlock
                      placeholder='e.g. <iframe src="https://www.google.com/maps/embed..." width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>'
                      errorMessage={formik.errors.mapUrl}
                      inputState={`${helper.getInputClasses(formik, 'mapUrl')}`}
                      {...formik.getFieldProps('mapUrl')}
                    />
                  </ContentBlock>
                </ContentBlock>

                <ContentBlock className="mt-30">
                  <ContentHeader
                    title="Event Image"
                    subtitle="Upload an image for the event"
                    headerSize="md"
                    subtitleSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                  <ContentBlock cols={6}>
                    {
                      !eventId
                        ? (
                          <ContentBlock className="mt-15">
                            <FileUploadBlock onupdatefiles={setFile} />
                          </ContentBlock>
                        )
                        : (
                          <>
                            <CustomBlock className="flex-center pt-15 pb-30" style={{ maxWidth: '300px' }}>
                              <Image
                                source={`${process.env.REACT_APP_API_URL}Attachments/${formik.values.imageUrl}`}
                                className="event-image pl-10 pr-10"
                              />
                            </CustomBlock>
                            <FileUploadBlock onupdatefiles={setFile} labelIdle="Replace image" />
                          </>
                        )
                    }
                  </ContentBlock>
                </ContentBlock>
              </Section>

              {/* ACTIONS */}
              <Section
                hasNoContainer
                className="content-container--card-style--with-shadow mt-30 mb-120"
              >
                <ContentBlock>
                  <ContentHeader
                    title="Actions"
                    headerSize="md"
                    className="alt-font fw-600 secondary--clr pb-15"
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <InputBlock
                    label="Primary Action Text"
                    placeholder="e.g. Learn More"
                    errorMessage={formik.errors.primaryButtonText}
                    inputState={`${helper.getInputClasses(formik, 'primaryButtonText')}`}
                    {...formik.getFieldProps('primaryButtonText')}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <SelectBlock
                    label="Primary Action Type"
                    options={linkTypes}
                    isClearable
                    errorMessage={formik.errors.primaryButtonActionType}
                    inputState={!formik.values.primaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'primaryButtonActionType')}`}
                    {...formik.getFieldProps('primaryButtonActionType')}
                    onMenuOpen={() => formik.setFieldTouched('primaryButtonActionType', true)}
                    value={primaryActionType}
                    onChange={(opt) => {
                      opt = opt === null ? [] : opt;
                      setPrimaryActionType(opt);
                      formik.setFieldValue('primaryButtonActionType', opt.value);
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Primary Action Link"
                    placeholder=""
                    errorMessage={formik.errors.primaryButtonLink}
                    inputState={!formik.values.primaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'primaryButtonLink')}`}
                    {...formik.getFieldProps('primaryButtonLink')}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <InputBlock
                    label="Secondary Action Text"
                    placeholder="e.g. Register Now"
                    errorMessage={formik.errors.secondaryButtonText}
                    inputState={`${helper.getInputClasses(formik, 'secondaryButtonText')}`}
                    {...formik.getFieldProps('secondaryButtonText')}
                  />
                </ContentBlock>

                <ContentBlock cols={3}>
                  <SelectBlock
                    label="Secondary Action Type"
                    options={linkTypes}
                    inputState={!formik.values.secondaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'secondaryButtonActionType')}`}
                    errorMessage={formik.errors.secondaryButtonActionType}
                    {...formik.getFieldProps('secondaryButtonActionType')}
                    onMenuOpen={() => formik.setFieldTouched('secondaryButtonActionType', true)}
                    value={secondaryActionType}
                    onChange={(opt) => {
                      opt = opt === null ? [] : opt;
                      setSecondaryActionType(opt);
                      formik.setFieldValue('secondaryButtonActionType', opt.value);
                    }}
                  />
                </ContentBlock>

                <ContentBlock cols={6}>
                  <InputBlock
                    label="Secondary Action Link"
                    placeholder=""
                    errorMessage={formik.errors.secondaryButtonLink}
                    inputState={!formik.values.secondaryButtonText ? 'disabled' : `${helper.getInputClasses(formik, 'secondaryButtonLink')}`}
                    {...formik.getFieldProps('secondaryButtonLink')}
                  />
                </ContentBlock>
              </Section>

              {/* PAGE ACTIONS */}
              <FixedActionsBar
                primaryActionText={eventId > 0 ? 'Save Changes' : 'Create Event'}
                primaryActionColor="success--bg"
                primaryActionOnClick={() => {
                  formik.handleSubmit();
                }}
                secondaryActionText="Cancel"
                secondaryActionTo="/cms/management/events"
                optionalActionText={eventId > 0 ? 'Return to Listing' : ''}
                optionalActionTo="/cms/management/events"
              />
            </FormBlock>
          </ContentBlock>
        </Section>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions
  }
)(EventManagementPage);