/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-undef */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
// ASSETS
import {
  TbSettings, TbBell, TbUserEdit, TbUsers, TbListSearch, TbMessage, TbLogout, TbCake, TbShieldCheck
} from 'react-icons/tb';
import {
  Modal, Button as BSButton, ButtonGroup, Fade
} from 'react-bootstrap';
import MUIDataTable from 'mui-datatables';
import BannerImage from '../../../../assets/img/banner-image_default.png';
import BusinessGrowthBackgroundTexture from '../../../../assets/img/background-texture_business-growth.png';
import DefaultMemberProfileImage from '../../../../assets/client/default-member-profile-image.png';
// COMPONENTS
import Banner from '../../../../components/Banner';
import CustomBlock from '../../../../components/CustomBlock';
import Overlay from '../../../../components/Overlay';
import Button from '../../../../components/Button';
import FormBlock from '../../../../components/FormBlock';
import SelectBlock from '../../../../components/SelectBlock';
// TABS
import MemberDetailsTab from './tabs/MemberDetailsTab';
import PostsTab from './tabs/PostsTab';
import CommunityTab from './tabs/CommunityTab';
import EngagePagesTab from './tabs/EngagePagesTab';
// MODALS
import PayPalSubscriptionModal from './PayPalSubscriptionModal';
import AccountSettingsModal from './AccountSettingsModal';
import AlertsModal from './AlertsModal';
// SERVICES AND HELPERS
import * as membershipService from '../../../../services/management/membershipService';
import * as UserService from '../../../../services/management/userService';
import * as authRedux from '../../../../redux/authRedux';
import * as alert from '../../../../redux/alertToastRedux';

const ProfilePage = (props) => {
  const { auth, showAlert, history, logout } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [membership, setMembership] = useState([]);
  const [user, setUser] = useState([]);
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'profile');
  const [membershipProfile, setMembershipProfile] = useState([]);
  // MODALS
  const [showPayPalSubscriptionModal, setShowPayPalSubscriptionModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showAlertsModal, setShowAlertsModal] = useState(false);

  useEffect(() => {
    getMembership();

    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  useEffect(() => {
    if (!membershipProfile.length > 0) {
      getMembershipProfile();
    }
  }, []);

  const getMembershipProfile = async () => {
    try {
      setIsLoading(true);
      const data = await membershipService.getMembershipProfileByUserId(auth.user.id);
      setMembershipProfile(data);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const getMembership = async () => {
    setIsLoading(true);
    try {
      const res = await membershipService.getLoggedInUserMembership();
      const { member } = res;
      setMembership(member);
      // console.log('auth\n', auth);
      // console.log('MEMBERSHIP \n', member);
      // console.log('MEMBERSHIP \n', member, '\n', 'auth \n', auth);
    } catch (ex) {
      console.log(ex);
      // showAlert({ text: ex.message, state: 'warning' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMembershipLogout = () => {
    logout();
    history.push('/');
  };

  const handleTabSelect = (key) => {
    setActiveTab(key);
    localStorage.setItem('activeTab', key);
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isTitleUppercase
        title="Profile"
        isDynamicBanner
        imageSource={BannerImage}
      />

      <CustomBlock className="main-content profile-page mt-0 gap-5">

        {/* PROFILE */}
        <CustomBlock className="position-relative w-100">
          {/* ACTION - BECOME A MEMBER - REGISTERED USERS ONLY */}
          {(membershipProfile.id && membershipProfile.isEligible && membershipProfile.membershipTier === 'Registered User') && (
            <CustomBlock
              className="d-flex align-items-center justify-content-center position-relative w-100 success--bg--gradient cursor-pointer"
              style={{ zIndex: '1' }}
              onClick={() => setShowPayPalSubscriptionModal(true)}
            >
              <span className="size-md fw-600 light--clr lh-normal py-15 user-select-none text-uppercase">
                Become a Member!
              </span>
            </CustomBlock>
          )}

          <CustomBlock
            className=""
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: '0',
              width: '100%',
              height: '100%',
              background: ` linear-gradient(180deg, #152c41, #ffe0c1), url(${BusinessGrowthBackgroundTexture})`,
              backgroundBlendMode: 'color',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          />

          <CustomBlock className="profile-overview content-section flex-column align-items-start mx-auto p-0 py-40 position-relative z-index-1">
            {/* PROFILE ACTIONS */}
            <CustomBlock className="actions-container d-flex justify-content-end flex-wrap w-100 p-2 mb-30 gap-2">
              {(membershipProfile.payPalTransactionId || parseInt(auth.accessLevel, 10) > 10) && (
                <>
                  <Button
                    text="Alerts"
                    className="size-xs secondary--bg--gradient fit-content user-select-none"
                    size="sm"
                    iconLeft={<TbBell strokeWidth={2.25} className="size-sm white--clr mr-2" />}
                    onClick={() => setShowAlertsModal(true)}
                    style={{ borderRadius: '300px' }}
                  />
                  {/* <Button
                    text="Account"
                    className="size-xs primary--bg--gradient fit-content user-select-none"
                    size="sm"
                    iconLeft={<TbSettings strokeWidth={2.25} className="size-sm white--clr mr-2" />}
                    onClick={() => setShowSettingsModal(true)}
                    style={{ borderRadius: '300px' }}
                  /> */}
                </>
              )}
              <Button
                text="Logout"
                className="size-xs danger--bg--gradient fit-content user-select-none"
                size="sm"
                iconLeft={<TbLogout strokeWidth={2.25} className="size-sm white--clr mr-2" />}
                onClick={handleMembershipLogout}
                style={{ borderRadius: '300px' }}
              />
            </CustomBlock>
            {/* PROFILE OVERVIEW */}
            <CustomBlock className="overview-section d-flex align-items-center flex-wrap gx-2 gy-4">
              <CustomBlock
                className="member-profile-image"
                style={{
                  backgroundImage: membership && membershipProfile.imageFilePath
                    ? `url(${process.env.REACT_APP_API_URL}Attachments${membershipProfile.imageFilePath})`
                    : `url(${DefaultMemberProfileImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: '300px',
                }}
              />

              <CustomBlock className="d-flex flex-column gap-1">
                <CustomBlock className="d-flex flex-column gap-2">
                  <h2 className="d-flex align-items-baseline flex-wrap gap-1 size-mdlg primary-animated-gradient--clr fw-500 user-select-none">
                    Welcome, <span className="size-xl fw-700">{membershipProfile.firstName} {membershipProfile.lastName}</span>
                  </h2>

                  {membershipProfile.membershipTier && (
                    <CustomBlock className="d-flex align-items-center gap-2 my-1">
                      <p
                        className="badge secondary-animated-gradient--bg light--clr size-xxs fw-500 px-2 py-1 fit-content lh-normal text-uppercase"
                        style={{ boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px' }}
                      >
                        {membershipProfile.membershipTier}
                      </p>
                    </CustomBlock>
                  )}

                  {membershipProfile.dateCreated && (
                    <CustomBlock className="d-flex align-items-center flex-wrap gap-2">
                      <TbCake strokeWidth={1.8} className="size-sm primary-lighter--clr" />
                      <p className="size-xs primary-lighter--clr fw-600 fit-content">
                        <span className="fw-400">Registered</span> {moment(membershipProfile.dateCreated).format('M/D/YYYY')}
                      </p>
                    </CustomBlock>
                  )}

                  {membershipProfile.membershipDateCreated && (
                    <CustomBlock className="d-flex align-items-center gap-2">
                      <TbShieldCheck strokeWidth={2} className="size-sm primary-lighter--clr" />
                      <p className="size-xs primary-lighter--clr fw-600 fit-content">
                        <span className="fw-400">Membership</span> {moment(membershipProfile.membershipDateCreated).format('M/D/YYYY')}
                      </p>
                    </CustomBlock>
                  )}

                  {/* {(membershipProfile.membershipDateExpiration && membershipProfile.membershipDateCreated) && (() => {
                    const duration = moment.duration(moment(membershipProfile.membershipDateExpiration).diff(moment()));
                    const months = duration.months();
                    const days = duration.days();

                    return (
                      <CustomBlock className="d-flex align-items-start gap-2">
                        <TbShieldOff strokeWidth={2} className="size-sm primary-lighter--clr" />
                        <p className="size-xs d-flex flex-column primary-lighter--clr fw-600 fit-content lh-normal">
                          <span>
                            <span className="fw-400">Expires</span> {moment(membershipProfile.membershipDateExpiration).format('MMM Do, YYYY')}
                          </span>
                          {' '}
                          <span className="d-flex gap-1 size-xxxs primary-lighter--clr fw-400 fit-content">
                            {months > 0 && `${months} month${months > 1 ? 's' : ''}`}
                            {days > 0 && ` ${days} day${days > 1 ? 's' : ''}`}
                          </span>
                        </p>
                      </CustomBlock>
                    );
                  })()} */}
                </CustomBlock>
              </CustomBlock>
            </CustomBlock>
          </CustomBlock>

          {/* PROFILE TABS */}
          <CustomBlock className="profile-tabs row w-100 mx-0 my-20">
            <ButtonGroup className="w-100">
              <CustomBlock
                className={`w-100 size-sm border-0 fw-500 text-uppercase py-10 ${activeTab === 'profile' ? 'active primary-animated-gradient--bg' : 'bg-transparent dark--clr'}`}
                onClick={() => handleTabSelect('profile')}
                style={{ boxShadow: 'none' }}
              >
                <CustomBlock className="tab d-flex align-items-center justify-content-center gap-2">
                  <TbUserEdit strokeWidth={2.5} size={20} className="" />
                  <span className="size-sm lh-normal">Profile</span>
                </CustomBlock>
              </CustomBlock>

              <CustomBlock
                className={`w-100 size-sm border-0 fw-500 text-uppercase py-10 ${activeTab === 'post' ? 'active primary-animated-gradient--bg' : 'bg-transparent dark--clr'}`}
                onClick={() => handleTabSelect('post')}
                style={{ boxShadow: 'none' }}
              >
                <CustomBlock className="tab d-flex align-items-center justify-content-center gap-2">
                  <TbMessage strokeWidth={2.5} size={20} className="" />
                  <span className="size-sm lh-normal">Posts</span>
                </CustomBlock>
              </CustomBlock>

              <CustomBlock
                className={`w-100 size-sm border-0 fw-500 text-uppercase py-10 ${activeTab === 'community' ? 'active primary-animated-gradient--bg' : 'bg-transparent dark--clr'}`}
                onClick={() => handleTabSelect('community')}
                style={{ boxShadow: 'none' }}
              >

                <CustomBlock className="tab d-flex align-items-center justify-content-center gap-2">
                  <TbUsers strokeWidth={2.5} size={20} className="" />
                  <span className="size-sm lh-normal">Community</span>
                </CustomBlock>
              </CustomBlock>

              <CustomBlock
                className={`w-100 size-sm border-0 fw-500 text-uppercase py-10 ${activeTab === 'engage-pages' ? 'active primary-animated-gradient--bg' : 'bg-transparent dark--clr'}`}
                onClick={() => handleTabSelect('engage-pages')}
                style={{ boxShadow: 'none' }}
              >
                <CustomBlock className="tab d-flex align-items-center justify-content-center gap-2">
                  <TbListSearch strokeWidth={2.5} size={22} className="" />
                  <span className="size-sm lh-normal">Engage Pages</span>
                </CustomBlock>
              </CustomBlock>
            </ButtonGroup>
          </CustomBlock>
        </CustomBlock>

        {/* TAB CONTENT */}
        {/* <CustomBlock className="content-section mt-30 py-40">
          <CustomBlock className="content-container--card w-100">
            <CustomBlock className="tabs justify-content-start m-0 p-20">
              <p className="size-sm dark--clr" style={{ opacity: '0.85' }}>
                Glad you’re here! Feel free to explore, reach out if you need anything, and make yourself at home.
              </p>

            </CustomBlock>
          </CustomBlock>
        </CustomBlock> */}

        {/* TAB CONTENT */}
        <CustomBlock className="content-section mt-10 p-0 pb-90">
          <CustomBlock className="content-container--card w-100">
            {activeTab === 'profile' && (
            <Fade in={activeTab === 'profile'}>
              <div>
                <MemberDetailsTab
                  title="Profile"
                  auth={auth}
                  setIsLoading={setIsLoading}
                  membership={membership}
                  setMembership={setMembership}
                  membershipProfile={membershipProfile}
                  setMembershipProfile={setMembershipProfile}
                  user={auth.user}
                  refreshMembership={getMembershipProfile}
                  {...props}
                />
              </div>
            </Fade>
            )}

            {activeTab === 'post' && (
            <Fade in={activeTab === 'post'}>
              <div>
                <PostsTab
                  title="Posts"
                  auth={auth}
                  {...props}
                />
              </div>
            </Fade>
            )}

            {activeTab === 'community' && (
            <Fade in={activeTab === 'community'}>
              <div>
                <CommunityTab
                  title="Community"
                  setIsLoading={setIsLoading}
                  setMembership={setMembership}
                  membership={membership}
                  {...props}
                />
              </div>
            </Fade>
            )}

            {activeTab === 'engage-pages' && (
            <Fade in={activeTab === 'engage-pages'}>
              <div>
                <EngagePagesTab
                  title="Engage Pages"
                  setIsLoading={setIsLoading}
                  setMembership={setMembership}
                  membership={membership}
                  {...props}
                />
              </div>
            </Fade>
            )}
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      {/* SETTINGS MODAL */}
      {showSettingsModal && (
        <AccountSettingsModal auth={auth} showSettingsModal={showSettingsModal} setShowSettingsModal={setShowSettingsModal} />
      )}

      {/* PAYPAL SUBSCRIPTION MODAL */}
      <PayPalSubscriptionModal
        show={showPayPalSubscriptionModal}
        onClose={() => setShowPayPalSubscriptionModal(false)}
        auth={auth}
        membership={membership}
      />

      {/* ALERTS MODAL */}
      <AlertsModal
        auth={auth}
        showAlertsModal={showAlertsModal}
        setShowAlertsModal={setShowAlertsModal}
      />
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, { ...authRedux.actions, ...alert.actions })(ProfilePage);